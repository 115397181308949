import * as Yup from 'yup'
import { ParsedUrlQuery } from 'querystring'
import { SelectOption, FormFieldProps } from './api/types'

export function stripQueryParam(query: ParsedUrlQuery, param: string) {
  // Delete keys from a next.js query object
  // We want an object of the non-slug query to pass queryParams to the backend
  const queryParams = Object.keys(query).reduce(
    (object: { [key: string]: string | string[] }, key) => {
      if (key !== param) {
        object[key] = query[key]
      }
      return object
    },
    {}
  )
  return queryParams
}

export function dynamicGtagWithEvent(gtag: string, event: string): string {
  return `
  <script async src=https://www.googletagmanager.com/gtag/js?id=${gtag}></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${gtag}');
    gtag('event', '${event}');
  </script>
  `
}

export function facebookPixelScriptWithDynamicType(
  type: string,
  facebook_pixel_id: string
): string {
  return `
  <!-- Facebook Pixel Code -->
<script>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '${facebook_pixel_id}');
fbq('track', '${type}');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=${facebook_pixel_id}&ev=${type}&noscript=1"
/></noscript>
<!-- End Facebook Pixel Code -->
  `
}
export function LinkedInScript(linkedin_partner_id: string): string {
  return `
  <script type="text/javascript">
  _linkedin_partner_id = "${linkedin_partner_id}";
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(_linkedin_partner_id);
  </script>
  <script type="text/javascript">
  (function(l) {if (!l){
    window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
    window.lintrk.q=[]}var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");b.type = "text/javascript";
    b.async = true;b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);})(window.lintrk);
    </script>
    <noscript>
    <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=${linkedin_partner_id}&fmt=gif" />
    </noscript>
  `
}

export function tiktokPixelScript(tiktok_pixel_id: string): string {
  return `
  <!-- TikTok Pixel Code -->
  <script>
  !function (w, d, t) {
    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
    ttq.load('${tiktok_pixel_id}');
    ttq.page();
  }(window, document, 'ttq');
</script>
<!-- End TikTok Pixel Code -->
  `
}

export function UrlBuilder(url: string, options: any) {
  if (!options) {
    return url
  }

  Object.keys(options).map(key => {
    if (options[key] === null || options[key] === '') {
      delete options[key]
    }
  })
  let esc = encodeURIComponent
  let query = Object.keys(options)
    .map(k => esc(k) + '=' + esc(options[k]))
    .join('&')
  url += '?' + query
  return url
}

export function getChoices(fieldsChoices?: []): Array<SelectOption> {
  return (
    fieldsChoices?.map(option => {
      return {
        label: option[1],
        value: option[0]
      }
    }) || []
  )
}

/*
  ----------------------------------------------------------------------------------------------------
  isNullOrEmpty()
  ----------------------------------------------------------------------------------------------------
  check a variable is null or empty
*/
export function isNullOrEmpty(input: any): boolean {
  // Null or empty
  if (input === null || input === undefined || input === '') {
    return true
  }

  // value = False
  if (input === 'False') {
    return true
  }

  // Array empty
  if (typeof input.length === 'number' && typeof input !== 'function') {
    return !input.length
  }

  // Blank string like '   '
  if (typeof input === 'string' && input.match(/\S/) === null) {
    return true
  }

  // Object empty
  if (input.constructor === Object && Object.keys(input).length === 0) {
    return true
  }

  return false
}

export function getFormInitValues(fields: any): any {
  let values: any = {}

  if (!isNullOrEmpty(fields)) {
    fields.map((field: any) => (values[field.name] = field.value || ''))
  }

  return values
}

export function getFieldValidRule(fieldType: string) {
  switch (fieldType) {
    case 'email':
      return Yup.string().email('Enter a valid email address')
    case 'number':
      return Yup.number()
    case 'url':
      return Yup.string().url('Enter a valid URL')
    default:
      return Yup.string()
  }
}

export function dynamicValidation(
  form?: Array<FormFieldProps>
): Yup.ObjectSchema {
  // loop through fields
  let fields: any = {}
  form &&
    form.map(field => {
      fields[field.name] = getFieldValidRule(field.type)

      if (field.field_block === 'privacypolicy') {
        fields[field.name] = Yup.boolean()
          .oneOf([true], 'Please accept the privacy policy')
          .required('Please accept the privacy policy')
      }

      // add required
      if (field.required)
        fields[field.name] = fields[field.name].required(
          `${field.label || 'Field'} is required`
        )
    })

  // generate the Yup object
  return Yup.object().shape(fields)
}

export function maxBounds(
  coordinates: Array<Array<number>>
): Array<Array<number>> {
  // Given a list of Longitudes/Latitudes return a bounding box

  let maxLng: number = coordinates[0][0]
  let minLng: number = coordinates[0][0]
  let maxLat: number = coordinates[0][1]
  let minLat: number = coordinates[0][1]

  coordinates.map(coord => {
    if (coord[0] > maxLng) {
      maxLng = coord[0]
    } else if (coord[0] < minLng) {
      minLng = coord[0]
    }

    if (coord[1] > maxLat) {
      maxLat = coord[1]
    } else if (coord[1] < minLat) {
      minLat = coord[1]
    }
  })
  return [
    [minLng, minLat],
    [maxLng, maxLat]
  ]
}

export function deboundRequest(functionName: Function, value?: string) {
  let timeOut: ReturnType<typeof setTimeout> | undefined
  if (timeOut) clearTimeout(timeOut)
  timeOut = setTimeout(() => {
    functionName(value)
  }, 1000)
}

export function isiOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const getJoinUrlParams = (url: string, gym?: string) => {
  if (url.includes('join.plus.fitness') && gym) {
    return `${url}?gym=${encodeURIComponent(gym)}#detail`
  }

  return url
}
